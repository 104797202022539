import React from "react";
import Heading from "../Homecomponent/Heading";

const About = () => {
  return (
    <>
      <div className="pageHeader py-4">
        <Heading title="About Us" />
      </div>
      <div className="container py-5">
        <img src="" alt="" />
        <p style={{ textAlign: "justify" }}>
          The Rotaract Club of Madhyapur, chartered on October 11th, 2011, operates within Rotary Club of Madhyapur, thimi, bhaktapur. It serves as a non-profit, non-political, and non-religious organization, dedicated to volunteer service. This club is specifically designed for students and young adults aged between 18 and 30.  

          RAC Madhyapur is actively involved in various community service initiatives, social events, professional development activities, and much more! 

          RAC Madhyapur welcomes you to visit Club and also invite to join regular meetings, fun-fellowship programs, and volunteer opportunities.
        </p>
      </div>
    </>
  );
};

export default About;
