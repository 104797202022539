const Objlist = [
  {
    id: 1,
    img: "fa-regular fa-5x fa-handshake",
    title: "Fellowship",
    para: "We facilitate collaboration among fellows, alumni, and experts in various fields. Our goal is to create a powerful network of individuals who can learn from each other, share knowledge, and collaborate on initiatives that address pressing societal challenges. ",
  },
  {
    id: 2,
    img: "fa-solid fa-5x fa-chart-line",
    title: "Outgrowth",
    para: "Our program aims to nurture the leadership potential of fellows by providing them with mentorship, resources, and opportunities for personal and professional growth. We believe that strong leaders have the capacity to effect meaningful change. ",
  },
  {
    id: 3,
    img: "fa-solid fa-5x fa-people-group",
    title: "Team Work",
    para: "We emphasize ethical leadership and growth as a core value, promoting transparency, integrity, and social responsibility in all activities and projects undertaken through teamwork. Our aim is to foster leaders who prioritize their team members. ",
  },
  {
    id: 4,
    img: "fa-solid fa-5x fa-gear",
    title: "Community Impact",
    para: "Rotaract Club of Madhyapur encourages participants to apply their skills and knowledge to make a positive impact on their communities and beyond. Fellows are supported in developing and implementing projects that address critical social, environmental, or economic issues. ",
  },
];

export default Objlist;
