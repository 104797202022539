import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Homecomponent/Navbar";
import Home from "./Pages/Homepage";
import About from "./Pages/Aboutpage";
import Mission from "./Pages/Missionpage";
import Pastpresident from "./Pages/Pastpresidentpage";
import Team from "./Pages/Teampage";
import Project from "./Pages/Projectpage";
import Gallery from "./Pages/Gallerypage";
import Contact from "./Pages/Contactpage";
import Footer from "./Homecomponent/Footer";

const MainApp = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/mission" element={<Mission />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/pastpresident" element={<Pastpresident />} />
        <Route exact path="/project" element={<Project />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
};

export default MainApp;
